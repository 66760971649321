import * as Fathom from 'fathom-client';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactGA from 'react-ga';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './utils/store';

// import configureStore from './utils/store';

import Header from './components/Header';
import ProtectedRoute from './components/ProtectedRoute';
// import Footer from './components/Footer';
import ActivityLoader from './components/ActivityLoader';
import Parent from './components/Parent';
import ErrorBoundary from './pages/ErrorBoundary';
import NoMatch404 from './pages/NoMatch404';
import { injectStore } from './utils/http';

// inject store to http api
injectStore(store);

const Login = Loadable({
  loader: () => import('./pages/Login'),
  loading: ActivityLoader,
});
const LoginToken = Loadable({
  loader: () => import('./pages/LoginToken'),
  loading: ActivityLoader,
});
const ConfirmUser = Loadable({
  loader: () => import('./pages/ConfirmUser'),
  loading: ActivityLoader,
});
const ResetPassword = Loadable({
  loader: () => import('./pages/ResetPassword'),
  loading: ActivityLoader,
});
const DocuSignReturn = Loadable({
  loader: () => import('./pages/DocuSignReturn'),
  loading: ActivityLoader,
});
const ForgotPassword = Loadable({
  loader: () => import('./pages/ForgotPassword'),
  loading: ActivityLoader,
});
const ChangePassword = Loadable({
  loader: () => import('./pages/ChangePassword'),
  loading: ActivityLoader,
});
const ContainerSearch = Loadable({
  loader: () => import('./pages/ContainerSearch'),
  loading: ActivityLoader,
});
const ContainerList = Loadable({
  loader: () => import('./pages/ContainerList'),
  loading: ActivityLoader,
});
const ContainerDashboard = Loadable({
  loader: () => import('./pages/ContainerDashboard'),
  loading: ActivityLoader,
});
const BillingDashboard = Loadable({
  loader: () => import('./pages/BillingDashboard'),
  loading: ActivityLoader,
});
const BookingDashboard = Loadable({
  loader: () => import('./pages/BookingDashboard'),
  loading: ActivityLoader,
});
const BlanketLOI = Loadable({
  loader: () => import('./pages/BlanketLOI'),
  loading: ActivityLoader,
});
const BlanketLOIDetails = Loadable({
  loader: () => import('./pages/BlanketLOIDetails'),
  loading: ActivityLoader,
});
const BookingRequest = Loadable({
  loader: () => import('./pages/BookingRequest'),
  loading: ActivityLoader,
});
const BookingRequestDetails = Loadable({
  loader: () => import('./pages/BookingRequestDetails'),
  loading: ActivityLoader,
});
const BookingUpdate = Loadable({
    loader: () => import('./pages/BookingUpdate'),
    loading: ActivityLoader,
});
const BookingUpdateDetails = Loadable({
    loader: () => import('./pages/BookingUpdateDetails'),
    loading: ActivityLoader,
});
const BookingHistory = Loadable({
  loader: () => import('./pages/BookingHistory'),
  loading: ActivityLoader,
});
const BookingCompare = Loadable({
  loader: () => import('./pages/BookingComparison'),
  loading: ActivityLoader,
});
const ContainerTracking = Loadable({
  loader: () => import('./pages/ContainerTracking'),
  loading: ActivityLoader,
});
const Registration = Loadable({
  loader: () => import('./pages/Registration'),
  loading: ActivityLoader,
});
const UserProfile = Loadable({
  loader: () => import('./pages/UserProfile'),
  loading: ActivityLoader,
});
const InfoCenter = Loadable({
  loader: () => import('./pages/InformationCenter'),
  loading: ActivityLoader,
});
const AdminProfileSearch = Loadable({
  loader: () => import('./pages/AdminProfileSearch'),
  loading: ActivityLoader,
});
const AdminProfileSearchDetails = Loadable({
  loader: () => import('./pages/AdminProfileSearchDetails'),
  loading: ActivityLoader,
});
const AdminAccountSearch = Loadable({
  loader: () => import('./pages/AdminAccountSearch'),
  loading: ActivityLoader,
});
const AdminAccountSearchDetails = Loadable({
  loader: () => import('./pages/AdminAccountSearchDetails'),
  loading: ActivityLoader,
});
const SupportHelp = Loadable({
  loader: () => import('./pages/SupportHelp'),
  loading: ActivityLoader,
});
const ShippingInstructions = Loadable({
  loader: () => import('./pages/ShippingInstructions'),
  loading: ActivityLoader,
});
const ShippingInstructionsDetails = Loadable({
  loader: () => import('./pages/ShippingInstructionsDetails'),
  loading: ActivityLoader,
});
const AdminEtaRuleSearch = Loadable({
  loader: () => import('./pages/AdminEtaRuleSearch'),
  loading: ActivityLoader,
});
const AdminEtaRuleSearchDetails = Loadable({
  loader: () => import('./pages/AdminEtaRuleSearchDetails'),
  loading: ActivityLoader,
});

const SalesView = Loadable({
  loader: () => import('./pages/SalesView'),
  loading: ActivityLoader,
});
const RepSalesView = Loadable({
  loader: () => import('./pages/RepSalesView'),
  loading: ActivityLoader,
});

const AdminEtaVvdExSearch = Loadable({
  loader: () => import('./pages/AdminEtaVvdExSearch'),
  loading: ActivityLoader,
});
const AdminEtaVvdExSearchDetails = Loadable({
  loader: () => import('./pages/AdminEtaVvdExSearchDetails'),
  loading: ActivityLoader,
});

const VGMDetails = Loadable({
  loader: () => import('./pages/VGMDetails'),
  loading: ActivityLoader,
});

const DetentionChargeCalculator = Loadable({
  loader: () => import('./pages/DetentionChargeCalculator'),
  loading: ActivityLoader,
});

// import styles from './App.module.css';

global.ReCAPTCHAKey = '6Lf2v-8UAAAAABziEznLhfVl8sDNiREcNE1FoDWk';
global.hCAPTCHAKey = 'a20760b3-e2f2-4a20-aac8-3eae7779eb4b';
global.GMapKey = 'AIzaSyBo8rIoPCdIUkpsaZ2CTWOFqDNaItqGws4';
// global.BMapKey = 'rdgg8qS9ZZoINqU9sqCNCVyXvx17PPMj'; //Baidu Map key
global.BMapKey = process.env.REACT_APP_BING_MAP_KEY;
global.API_AuthToken =
  'c2FuZGJveC1jYXJnby10cmFjay1hcGktc2VydmljZS1rZXk6SE9nODYzaDVJczFpN01WZkdWdXdmNk5KQmFtREdMTjE0ZVZHQ0VaMg==';

//  User Directory Service APIs
global.URL_UserReg = process.env.REACT_APP_USER_API_URL + '/dir/user';
global.URL_UserConf =
  process.env.REACT_APP_USER_API_URL + '/dir/user/confirmation';
global.URL_UserForgot = process.env.REACT_APP_USER_API_URL + '/dir/user/forgot';
global.URL_ResetPass =
  process.env.REACT_APP_USER_API_URL + '/dir/user/confirmforgot';
global.URL_Login = process.env.REACT_APP_USER_API_URL + '/dir/login';
global.URL_TokenRefresh =
  process.env.REACT_APP_USER_API_URL + '/dir/login/refresh';
global.URL_Logout = process.env.REACT_APP_USER_API_URL + '/dir/logout';
global.URL_ChangePass = process.env.REACT_APP_USER_API_URL + '/dir/user/change';
global.URL_ResendCode = process.env.REACT_APP_USER_API_URL + '/dir/user/resend';

// User Management Service APIs
global.URL_UserProfileLookupUpdate =
  process.env.REACT_APP_USER_API_URL + '/mgmt/cp/user';
global.URL_AdminUserReset =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/user/reset';
global.URL_AdminEmailLookUp =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/user/email';
global.URL_AdminUserSearch =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/user';
global.URL_AdminUserDetail =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/user/authEvents';
global.URL_AdminOrgLookUp =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/cp/org';
global.URL_AdminProfileSearch =
  process.env.REACT_APP_USER_API_URL + '/mgmt/admin/cp/user';
global.URL_UserAssume =
  process.env.REACT_APP_USER_API_URL + '/mgmt/user/assume';

// CargoTrack API's
global.URL_SearchByBooking =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargo?';
global.URL_SearchDetails =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargo/detail?';
global.URL_SearchByBookingNoAuth =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargopub?';
global.URL_SearchDetailsNoAuth =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargopub/detailpub?';
global.URL_GetVVDs =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargo/vvds';
global.URL_GetCardData =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/dashboard/';
global.URL_GetLocation =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargo/location?';
global.URL_BookingSearch =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bookings';
global.URL_BookingRequestRefData =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/refdata';
global.URL_BookingRequestRefDataNoAuth =
  process.env.REACT_APP_CARGO_API_URL + '/v2/cargotrack/pub/refdata/';
global.URL_TranslateRefData =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/cargo/translateRefData';
global.URL_BookingDetail =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking';
global.URL_BookingUpdateDetail =
  process.env.REACT_APP_CARGO_API_URL + '/v2/cargotrack/gl/bookingcorr/view'; // '/gates/booking' /gates-service/api/v1/booking/
global.URL_BookingSaveDraft =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/draft';
global.URL_BookingConfirmation =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/confirmation';
global.URL_VesselSearchByVVD =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/vessel/vvd?';
global.URL_VesselSearchByDate =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/vessel/date?';
global.URL_GetBRGridPreference =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/booking/prefs?';
global.URL_BookingUpload =
  process.env.REACT_APP_BOOKING_UPLOAD_URL + '/cargotrack/edi/form/url';
global.URL_ShippingSearch =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/instructions';
global.URL_BookingRefValidation =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bookings/validate';
global.URL_ShippingDetail =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/instruction';
global.URL_ShippingContainerLookup =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/containers';
global.URL_ShippingSaveDraft =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/draft';
global.URL_ShippingSubmit =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si';
global.URL_BookingSaveSubmit =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bookings/forms';
global.URL_SIFormDownload =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/form';
global.URL_SIBLDraft =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/bldraft';
global.URL_SIBLDocsSend =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/blDocsSend';
global.URL_SIExcelSaveSubmit =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/instructions/forms';
global.URL_SIExcelValidate =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/instructions/validate';
global.URL_SIExcelParseUpload =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/si/instructions/xlsparse';
global.countryCode = process.env.REACT_APP_COUNTRY_CODE;
global.URL_GetExtTokenInfo =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/ext/gettoken';
global.URL_ManualAddition =
  process.env.REACT_APP_CARGO_API_URL + '/v2/cargotrack/gl/vesselAllocation/manual';
// ETA apis
global.URL_EtaRule_Search =
  process.env.REACT_APP_CARGO_API_URL +
  '/cargotrack/maintenance/eta/searchPost';
global.URL_EtaRule_Details =
  process.env.REACT_APP_CARGO_API_URL +
  '/cargotrack/maintenance/eta/createUpdate';

global.URL_EtaExceptionRule_Search =
  process.env.REACT_APP_CARGO_API_URL +
  '/cargotrack/maintenance/eta/exception/search';
global.URL_EtaExceptionRule_Details =
  process.env.REACT_APP_CARGO_API_URL +
  '/cargotrack/maintenance/eta/exception/createUpdate';
// VGM apis
global.URL_VGMGetCarrierBookingTypes =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vgm/getcarriers';
global.URL_VGMSearchByBooking =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vgm/search';
global.URL_VGMSaveBkEquipments =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vgm/save';
global.URL_VGMSaveMultipleBkEquipments =
  process.env.REACT_APP_CARGO_API_URL + '/v2/cargotrack/gl/vgm/saveMultipleBooking';
global.URL_VGMValidContainer =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vgm/isvalidcontainer';

// Bldoc apis
global.URL_BldocSearch =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/search';
global.URL_BldocOTLoiSubmit =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/onetimeloi';
global.URL_BldocBlanketLoiSearch =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/blanketloi/search';
global.URL_BldocBlanketLoiAdd =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/blanketloi/add';
global.URL_BldocBlanketLoiUpdate =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/blanketloi/update';
global.URL_BldocBlanketLoiGet =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/blanketloi/get';
global.URL_BldocBlanketLoiTariff =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/blanketloi/tariff';
global.URL_BldocDownload =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/download';
global.URL_BldocStatusHistory =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/statushistory';
global.URL_BldocDownloadUrl =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/bldoc/downloadloi';
global.URL_BldocBlanketShipperApproval =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/doc/sign/blanket';
global.URL_BldocOnetimeShipperApproval =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/doc/sign/onetime';
global.URL_BldocSignComplete =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/doc/sign/complete';
global.URL_BldocSignCheckCallbackStatus =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/doc/sign/checkcallback';
global.URL_BldocSignCheckgroup =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/doc/sign/checkgroup';

global.URL_SalesViewSearch =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/search';
global.URL_SalesViewUpdate =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/update';
global.URL_SalesViewRepVVD =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/vvd';
global.URL_SalesViewRepPort =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/pol';
global.URL_SalesViewRepSalesRep =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/salesRep';
global.URL_RepSalesViewAccountName =
  process.env.REACT_APP_CARGO_API_URL + '/cargotrack/vvdAllocation/account';
global.URL_RepSalesViewSearch =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/search';
global.URL_RepSalesViewUpdate =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/update';
global.URL_RepSalesViewDownload =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/download';
global.URL_RepSalesViewDownloadV2 =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/gl/vesselAllocation/download/v2';
global.URL_RepSalesViewUpload =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/upload';
global.URL_RepSalesViewUploadV2 =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/gl/vesselAllocation/upload/v2';
global.URL_RepSalesViewNotify =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/notify';
global.URL_RepSalesViewHistory =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/history';
global.URL_RepSalesViewRepDefault =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/vesselAllocation/salesRep/default';
global.URL_EirHistory =
  process.env.REACT_APP_CARGO_API_URL +
  '/v2/cargotrack/booking/eir/history';
global.URL_BookingCorrSearch =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/search';
global.URL_BookingCorrHistory =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/history';
global.URL_BookingCorrCompare =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/compare';
global.URL_BookingCorrList =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/';
global.URL_BookingCorrController =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/action';
global.URL_BookingCorrControllerUpdate =
  process.env.REACT_APP_CARGO_API_URL + 
  '/v2/cargotrack/gl/bookingcorr/update';
global.URL_BookingCorrCancelBooking = 
  process.env.REACT_APP_CARGO_API_URL +
  "/v2/cargotrack/gl/bookingcorr/deleteBooking";
global.URL_BookingCorrReApproveBooking =
  process.env.REACT_APP_CARGO_API_URL +
  "/v2/cargotrack/gl/bookingcorr/reApproveBooking";
global.URL_BookingCorrPermissions =
  process.env.REACT_APP_CARGO_API_URL +
  "/v2/cargotrack/gl/bookingcorr/checkPermission";
global.URL_BookingCorrIsLatest =
  process.env.REACT_APP_CARGO_API_URL +
  "/v2/cargotrack/gl/bookingcorr/checkLatestBookingData"
// Detention Charge Calc API/s
global.URL_DETENTION_RATE =
  process.env.REACT_APP_CARGO_API_URL + "/v2/cargotrack/detention/rate";
global.URL_DETENTION_RATE_PUBLIC =
  process.env.REACT_APP_CARGO_API_URL + "/v2/cargotrack/detention/rate/pub";
global.URL_ServiceContractRefData =
  process.env.REACT_APP_CARGO_API_URL + "/v2/cargotrack/gl/detention/svcContract";
global.URL_VGMRefValidation =
  process.env.REACT_APP_CARGO_API_URL + "/v2/cargotrack/gl/vgm/validate";
//
//
// load analytics service based on country.
// Needed because GA does not work in CN
//
if (
  'CN' === process.env.REACT_APP_COUNTRY_CODE ||
  'sandbox' === process.env.REACT_APP_STAGE
) {
  console.debug('- load fathom/');
  Fathom.load(process.env.REACT_APP_FATHOM_SITE_ID);
}
if ('US' === process.env.REACT_APP_COUNTRY_CODE) {
  console.debug('- init ga');
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKER);
}

global.trackAnalyticsPageView = (pageurl) => {
  if (process.env.REACT_APP_COUNTRY_CODE === 'CN') {
    console.info('- track fathom/ page view');
    Fathom.trackPageview({ pageurl });
  } else {
    console.info('- track ga page view');
    ReactGA.pageview(pageurl);
  }
};

global.trackAnalyticsEvent = (category, action, pageurl, user) => {
  console.debug(
    `Analytics Event - category: ${category} action: ${action} pageurl: ${pageurl} user: ${user}`
  );
  if (process.env.REACT_APP_COUNTRY_CODE === 'CN') {
    console.info('- send fathom/ event');
    console.log(`---- action: ${action}`);
    Fathom.trackGoal(action, 0);
  } else {
    console.info('- send ga event');
    ReactGA.event({ category, action, label: user });
  }
};

global.updateUserObject = (user, response) => {
  let newUser = _.cloneDeep(user);
  newUser.idToken = response.IdToken;
  newUser.accessToken = response.AccessToken;
  newUser.expiresIn = response.ExpiresIn;
  newUser.tokenType = response.TokenType;
  newUser.loginTimeStamp = moment().toDate();
  return newUser;
};

const App = () => {
  //useEffect(() => {
  //    document.title = "Matson Cargo Portal " + "("+ store.getState().appinfo.instanceId + ")";
  //  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Parent>
            <Header />
            <ErrorBoundary>
              <Switch>
                <Route exact path="/" component={ContainerSearch} />
                <Route exact path="/signin" component={Login} />
                <Route exact path="/detentionChargeCalculator" component={DetentionChargeCalculator}/>
                <Route
                  exact
                  path="/signintoken/:username/:token"
                  component={LoginToken}
                />
                <Route
                  exact
                  path="/confirmUser/:username"
                  component={ConfirmUser}
                />
                <Route
                  exact
                  path="/confirmUser/:username/:code"
                  component={ConfirmUser}
                />
                <Route
                  exact
                  path="/resetPassword/:username"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/resetPassword/:username/:code"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/forgetPassword"
                  component={ForgotPassword}
                />
                <Route exact path="/registration" component={Registration} />
                <Route
                  exact
                  path="/informationCenter"
                  component={InfoCenter}
                />
                <Route
                  exact
                  path="/containerList/:searchType=:searchQuery"
                  component={ContainerList}
                />
                <Route
                  exact
                  path="/containerDetails/:bookingNo/:containerNo"
                  component={ContainerTracking}
                />
                <Route exact path="/vgmDetails" component={VGMDetails} />
                <Route
                  exact
                  path="/docuSignReturn"
                  component={DocuSignReturn}
                />
                <ProtectedRoute
                  exact
                  path="/search"
                  component={ContainerSearch}
                />
                <ProtectedRoute
                  exact
                  path="/containerdash"
                  component={ContainerDashboard}
                />
                <ProtectedRoute
                  exact
                  path="/billingdoc"
                  component={BillingDashboard}
                />
                <ProtectedRoute exact path="/salesView" component={SalesView} />
                <ProtectedRoute
                  exact
                  path="/repSalesView"
                  component={RepSalesView}
                />
                <ProtectedRoute
                  exact
                  path="/bookingdash"
                  component={BookingDashboard}
                />
                <ProtectedRoute
                  exact
                  path="/blanketloi"
                  component={BlanketLOI}
                />
                <ProtectedRoute
                  exact
                  path="/blanketloidetails"
                  component={BlanketLOIDetails}
                />
                <ProtectedRoute
                  exact
                  path="/bookingRequest"
                  component={BookingRequest}
                />
                <ProtectedRoute
                  exact
                  path="/bookingRequestDetails"
                  component={BookingRequestDetails}
                />
                <ProtectedRoute
                  exact
                  path="/bookingUpdate"
                  component={BookingUpdate}
                />
                <ProtectedRoute
                  exact
                  path="/bookingUpdateDetails"
                  component={BookingUpdateDetails}
                />
                <ProtectedRoute
                  exact
                  path="/bookinghistory"
                  component={BookingHistory}
                />
                <ProtectedRoute
                  exact
                  path="/bookingCompare"
                  component={BookingCompare}
                />
                <ProtectedRoute
                  exact
                  path="/shippingInstructions"
                  component={ShippingInstructions}
                />
                <ProtectedRoute
                  exact
                  path="/shippingInstructionsDetails"
                  component={ShippingInstructionsDetails}
                />
                <ProtectedRoute exact path="/profile" component={UserProfile} />
                <ProtectedRoute
                  exact
                  path="/adminAccountSearch"
                  component={AdminAccountSearch}
                />
                <ProtectedRoute
                  exact
                  path="/adminAccountSearchDetails/:username"
                  component={AdminAccountSearchDetails}
                />
                <ProtectedRoute
                  exact
                  path="/adminProfileSearch"
                  component={AdminProfileSearch}
                />
                <ProtectedRoute
                  exact
                  path="/adminProfileSearchDetails/:username"
                  component={AdminProfileSearchDetails}
                />
                <ProtectedRoute exact path="/support" component={SupportHelp} />
                <ProtectedRoute
                  exact
                  path="/changePassword"
                  component={ChangePassword}
                />
                <ProtectedRoute
                  exact
                  path="/adminEtaRuleSearch"
                  component={AdminEtaRuleSearch}
                />
                <ProtectedRoute
                  exact
                  path="/adminEtaRuleSearchDetails/:ruleId"
                  component={AdminEtaRuleSearchDetails}
                />
                <ProtectedRoute
                  exact
                  path="/adminEtaRuleExceptionSearch"
                  component={AdminEtaVvdExSearch}
                />
                <ProtectedRoute
                  exact
                  path="/adminEtaRuleExceptionDetails/:ruleId"
                  component={AdminEtaVvdExSearchDetails}
                />
                <ProtectedRoute
                  exact
                  path="/detentionChargeCalculator"
                  component={DetentionChargeCalculator} />
                <Route path="*" component={NoMatch404} />
              </Switch>
            </ErrorBoundary>
          </Parent>
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
