import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Nav, Navbar, NavDropdown, Form, Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import jwt from 'jwt-decode';
import moment from 'moment';
import _ from 'lodash';
import { calcRegion,isEnableDemoFeatures } from './../utils/CTUtils';
import {
  setClientInfo,
  resetUser,
  resetDropdowns,
  setUser,
  setAssumeUser,
  resetAssumeUser,
  resetConDashSearchCr,
  resetBookingDashSearch,
  resetVgmLegalInfo,
  resetSelectedFilters,
} from './../utils/actions';
import ActivityLoader from './../components/ActivityLoader';
import AlertDialog from './../components/AlertDialog';
import http from './../utils/http';
import styles from './css/Header.module.css';
import {
  DOCUMENT_TYPE_VGM_DETAILS,
  BL_DOC_PRIV_LOI_OT,
  BL_DOC_PRIV_LOI_BL,
  BL_DOC_PRIV_OBL_DL,
  BL_DOC_PRIV_SWB_DL,
  BL_DOC_PRIV_DE,

} from '../utils/CTUtils';

class Header extends Component {
  state = {
    isShowingModal: false,
    isShowingModalUnassume: false,
    assumeUserVal: '',
    txtAssumeUser: '',
    user: this.props.user,
    showLoader: false,
    showAlertDialog: false,
    alertDialogMsg: '',
    alertDialogButtons: null,
  };

  componentDidMount() {
    this.validateUserSession();
  }

  validateUserSession = () => {
    const { user } = this.props;

    var totalLoginDuration = moment.duration(
      moment().diff(moment(user.loginTimeStamp))
    );
    // console.log('Session Active from (Sec): ', totalLoginDuration.asSeconds());
    // console.log('session should expires in (Sec) : ', user.expiresIn);

    if (totalLoginDuration.asSeconds() > Number(user.expiresIn)) {
      this.didLogout(() =>
        this.props.history.push({
          pathname: `/signin`,
          state: { sessionExpired: true },
        })
      );
    } else {
      //do nothing and keep same session valid.
      
    }
    console.log(this.props)
    fetch('https://ipapi.co/json')
      .then((res) => res.json())
      .then((r) => {
        if (r) {
          this.props.setClientInfo({
            ip: r.ip,
            city: r.city,
            region_code: r.region_code,
            continent_code : r.continent_code,
            country: r.country,
          });
        }
      })
      .catch(() => {
        console.log('error fetching load city');
        this.props.setClientInfo({
          ip: '127.0.0.1',
          city: 'NF',
          continent_code : 'NA',
          region_code: 'NF',
          country: 'NF',
        });
      });
  };

  getPathDetails = (location) => {
    if (
      (String(this.props.location.pathname).length === 1 &&
        String(this.props.location.pathname).localeCompare(location) === 0) ||
      (String(this.props.location.pathname).length === 7 &&
        String(this.props.location.pathname).localeCompare(location) === 0)
    )
      return true;
    // } else if (this.props.location.pathname.indexOf(location) >= 0) {
    else if (this.props.location.pathname.localeCompare(location) === 0)
      return true;
    else return false;
  };

  changeLanguage = (lang) => {
    localStorage.setItem('selectedLanguage', lang);
    this.props.i18n.changeLanguage(lang);
    this.props.setClientInfo({ lang: lang });
  };

  didLogin = () => {
    this.props.history.push({
      pathname: `/signin`,
      state: { sessionExpired: false },
    });
    this.resetAppData();
  };

  resetAppData = () => {
    this.props.resetConDashSearchCr();
    this.props.resetBookingDashSearch();
  };
  didLogout = (callback) => {
    const { user } = this.props;
    sessionStorage.removeItem('BDdays');
    sessionStorage.removeItem('BDvvd');
    sessionStorage.removeItem('BDendDt');
    sessionStorage.removeItem('BDstartDt');
    sessionStorage.removeItem('BDtxtBookingVal');
    sessionStorage.removeItem('BDchkEDIRef');
    sessionStorage.removeItem('BDchkBKG');
    sessionStorage.removeItem('CDdays');
    sessionStorage.removeItem('CDvvd');
    sessionStorage.removeItem('CDendDt');
    sessionStorage.removeItem('CDstartDt');
    sessionStorage.removeItem('CDtxtBookingVal');
    sessionStorage.removeItem('CDchkEDIRef');
    sessionStorage.removeItem('CDchkBKG');

    if (user.username !== '') {
      global.trackAnalyticsEvent(
        'Logout',
        `User logged out`,
        this.props.location.pathname,
        user.username
      );
      http.post(
        global.URL_Logout,
        {
          accessToken: this.props.user.accessToken,
          webid: user?.username,
        },
        { headers: { authorization: `${user.tokenType} ${user.idToken}` } },
        () => {},
        user,
        () => {
          this.props.resetSelectedFilters();
          this.props.resetAssumeUser();
          this.props.resetUser();
          this.props.resetDropdowns();
          this.props.resetVgmLegalInfo();
          this.resetAppData();
        }
      );
    }
    callback && callback();
  };

  didAdminAccountSearch = () =>
    this.props.history.push({ pathname: `/adminAccountSearch` });
  didAdminProfileSearch = () =>
    this.props.history.push({ pathname: `/adminProfileSearch` });
  didAdminEtaRuleSearch = () =>
    this.props.history.push({ pathname: `/adminEtaRuleSearch` });
  didAdminEtaRuleExceptionSearch = () =>
    this.props.history.push({ pathname: `/adminEtaRuleExceptionSearch` });
  userGuide = () => {
    window.open('./web_guideline.htm');
  };

  userProfile = () => this.props.history.push({ pathname: `/profile` });

  infoCenter = () => this.props.history.push({ pathname: '/informationCenter'})

  changePassword = () =>
    this.props.history.push({ pathname: `/changePassword` });

  didSupportHelp = () => this.props.history.push({ pathname: `/support` });

  onAssumeChange = () => {
    const { assumeUser } = this.props;
    this.setState({
      txtAssumeUser: assumeUser ? assumeUser.username : '',
      isShowingModalUnassume: true,
    });
  };

  toggleLoader = (showLoader) => this.setState({ showLoader });

  didSubmitAssumeUser = () => {
    let assumeVal = this.state.txtAssumeUser;
    const { user } = this.props;
    this.setState({ isShowingModal: false });
    http.post(
      global.URL_UserAssume,
      { username: assumeVal },
      { headers: { authorization: `${user.tokenType} ${user.idToken}` } },
      this.toggleLoader,
      user,
      (responseData, error) => {
        if (responseData === null)
          this.setState({ showAlertDialog: true, alertDialogMsg: error });
        else if (responseData === 'Refresh Token Needed') {
          http.refreshToken(
            global.URL_TokenRefresh,
            this.props.user,
            this.toggleLoader,
            (tokenResponse) => {
              this.props.setUser(
                global.updateUserObject(
                  this.props.user,
                  tokenResponse.AuthenticationResult
                )
              );
              this.didSubmitAssumeUser();
            }
          );
        } else if (responseData === 'Session Expired')
          this.props.history.push({
            pathname: `/signin`,
            state: { sessionExpired: true },
          });
        else if (responseData.assumedUser.username) {
          const { groups } = responseData.assumedUser;
          if (groups && groups.length > 0) {
            global.trackAnalyticsEvent(
              'Assume User',
              `Assume user`,
              this.props.location.pathname,
              user.username
            );
            this.setState({ assumeUserVal: assumeVal, isShowingModal: false });
            this.props.setAssumeUser(responseData.assumedUser);
            setTimeout(() => window.location.reload(), 1000);
          } else {
            this.setState({
              showAlertDialog: true,
              alertDialogMsg:
                'No permission setup for this user , ' + assumeVal,
            });
          }
        }
      }
    );
  };

  // handleClick = () => this.setState({ isShowingModal: true });

  handleClickUnassume = () => this.setState({ isShowingModalUnassume: true });
  handleCloseUnassume = () => {
    this.setState({ isShowingModalUnassume: false, assumeUserVal: '' });
    this.props.resetAssumeUser();
    setTimeout(() => window.location.reload(), 1000);
  };

  shouldRenderBookingDashboard = () => {
    const { assumeuser, user } = this.props;

    if (assumeuser !== null) {
      if (_.includes(assumeuser.groups, 'BD'))
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/bookingdash' }}
            as={Link}
            eventKey="2"
          >
            <p
              className={
                this.getPathDetails('/bookingdash')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.bookingDashboard')}
            </p>
          </Nav.Link>
        );
    } else if (_.includes(jwt(user.idToken)['cognito:groups'], 'BD')) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/bookingdash' }}
          as={Link}
          eventKey="2"
        >
          <p
            className={
              this.getPathDetails('/bookingdash')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.bookingDashboard')}
          </p>
        </Nav.Link>
      );
    }
  };
  shouldRenderRepSalesView = () => {
    const { assumeuser, user } = this.props;
    let hasPermission = false;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, 'VA') ||
        _.includes(assumeuser.groups, 'VU')
      ) {
        hasPermission = true;
      }
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], 'VA') ||
      _.includes(jwt(user.idToken)['cognito:groups'], 'VU')
    ) {
      hasPermission = true;
    }
    if (hasPermission === false) return null;

    return (
      <Nav.Link
        className={styles.navLink}
        to={{ pathname: '/repSalesView' }}
        as={Link}
        eventKey="2"
      >
        <p
          className={
            this.getPathDetails('/repSalesView')
              ? styles.titleText_Bold
              : styles.titleText
          }
        >
          {this.props.t('header.repSalesViewMenu')}
        </p>
      </Nav.Link>
    );
  };

  shouldRenderSalesView = () => {
    const { assumeuser, user } = this.props;
    let hasPermission = false;
    if (assumeuser !== null) {
      if (_.includes(assumeuser.groups, 'VS')) {
        hasPermission = true;
      }
    } else if (_.includes(jwt(user.idToken)['cognito:groups'], 'VS')) {
      hasPermission = true;
    }
    if (hasPermission === false) return null;

    return (
      <Nav.Link
        className={styles.navLink}
        to={{ pathname: '/salesView' }}
        as={Link}
        eventKey="2"
      >
        <p
          className={
            this.getPathDetails('/salesView')
              ? styles.titleText_Bold
              : styles.titleText
          }
        >
          {this.props.t('header.salesView')}
        </p>
      </Nav.Link>
    );
  };
  shouldRenderDetentionCalc = () => {
    const { assumeuser, user } = this.props;
  
    if (assumeuser !== null && (user.continent_code === 'AS' || process.env.REACT_APP_STAGE !== "production")) {
      if (_.includes(assumeuser.groups, BL_DOC_PRIV_DE)) {
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/detentionChargeCalculator' }}
            as={Link}
            eventKey="2"
          >
            <p
              className={
                this.getPathDetails('/detentionChargeCalculator')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.detention')}
            </p>
          </Nav.Link>
        );
      }
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_DE) &&
      (user.continent_code === 'AS' || process.env.REACT_APP_STAGE !== "production")
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/detentionChargeCalculator' }}
          as={Link}
          eventKey="2"
        >
          <p
            className={
              this.getPathDetails('/detentionChargeCalculator')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.detention')}
          </p>
        </Nav.Link>
      );
    }
  };
  

  shouldRenderBlanketLOI = () => {
    const { assumeuser, user } = this.props;

    if (assumeuser !== null) {
      if (_.includes(assumeuser.groups, BL_DOC_PRIV_LOI_BL))
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/blanketloi' }}
            as={Link}
            eventKey="2"
          >
            <p
              className={
                this.getPathDetails('/blanketloi')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.BlanketLOI')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_LOI_BL)
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/blanketloi' }}
          as={Link}
          eventKey="2"
        >
          <p
            className={
              this.getPathDetails('/blanketloi')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.BlanketLOI')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderContainerDashboard = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, 'TD') ||
        _.includes(assumeuser.groups, 'TD')
      )
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/containerdash' }}
            as={Link}
            eventKey="3"
          >
            <p
              className={
                this.getPathDetails('/containerdash')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.conatinerDashbaord')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], 'TD') ||
      _.includes(jwt(user.idToken)['cognito:groups'], 'TD')
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/containerdash' }}
          as={Link}
          eventKey="3"
        >
          <p
            className={
              this.getPathDetails('/containerdash')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.conatinerDashbaord')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderBillingDashboard = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, BL_DOC_PRIV_LOI_OT) ||
        _.includes(assumeuser.groups, BL_DOC_PRIV_LOI_BL) ||
        _.includes(assumeuser.groups, BL_DOC_PRIV_SWB_DL) ||
        _.includes(assumeuser.groups, BL_DOC_PRIV_OBL_DL)
      )
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/billingdoc' }}
            as={Link}
            eventKey="3"
          >
            <p
              className={
                this.getPathDetails('/billingdoc')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.billingDashboard')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_LOI_OT) ||
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_LOI_BL) ||
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_SWB_DL) ||
      _.includes(jwt(user.idToken)['cognito:groups'], BL_DOC_PRIV_OBL_DL)
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/billingdoc' }}
          as={Link}
          eventKey="3"
        >
          <p
            className={
              this.getPathDetails('/billingdoc')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.billingDashboard')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderUserProfile = () => {
    const { user } = this.props;
    if (jwt(user.idToken)['cognito:groups']) {
      return (
        <NavDropdown.Item eventKey="8" onClick={this.userProfile}>
          User Profile
        </NavDropdown.Item>
      );
    } else return null;
  };
  renderInformationCenterLink = () => {
    const { t, user } = this.props; // Assuming user is passed as a prop
  
    // Check if the continent is Asia or if the app is not in production
    if (user.continent_code === 'AS' || process.env.REACT_APP_STAGE !== 'production') {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/informationCenter' }}
          as={Link}
          eventKey="100"
        >
          <p
            className={
              this.getPathDetails('/informationCenter')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {t('informationCenter.txtTitle')}
          </p>
        </Nav.Link>
      );
    }
  
    // Return null if none of the conditions are met
    return null;
  };
  
  
  
  shouldRenderInformationCenter = () => {
    const { user } = this.props;
  
    // Check if the user's continent code is 'AS' and render the NavDropdown.Item conditionally
    if (user.continent_code === 'AS' || process.env.REACT_APP_STAGE !== "production") {
      return (
        <NavDropdown.Item eventKey="9" onClick={this.infoCenter}>
          {this.props.t("header.informationCenter")}
        </NavDropdown.Item>
      );
    }
  
    // Return null if the condition is not met
    return null;
  };
  

  shouldRenderBookingRequest = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, 'BR') ||
        _.includes(assumeuser.groups, 'BS')
      )
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/bookingRequest' }}
            as={Link}
            eventKey="5"
          >
            <p
              className={
                this.getPathDetails('/bookingRequest') ||
                this.getPathDetails('/bookingRequestDetails')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.bookingRequest')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], 'BR') ||
      _.includes(jwt(user.idToken)['cognito:groups'], 'BS')
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/bookingRequest' }}
          as={Link}
          eventKey="5"
        >
          <p
            className={
              this.getPathDetails('/bookingRequest') ||
              this.getPathDetails('/bookingRequestDetails')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.bookingRequest')}
          </p>
        </Nav.Link>
      );
    }
  };


  shouldRenderBookingUpdate = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, 'BC') ||
        _.includes(assumeuser.groups, 'BP')
      )
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/bookingUpdate' }}
            as={Link}
            eventKey="5"
          >
            <p
              className={
                this.getPathDetails('/bookingUpdate') ||
                this.getPathDetails('/bookingUpdateDetails')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.bookingCorrection')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], 'BC') ||
      _.includes(jwt(user.idToken)['cognito:groups'], 'BP')
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/bookingUpdate' }}
          as={Link}
          eventKey="5"
        >
          <p
            className={
              this.getPathDetails('/bookingUpdate') ||
              this.getPathDetails('/bookingUpdateDetails')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.bookingCorrection')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderShippingInstruction = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (
        _.includes(assumeuser.groups, 'SI') ||
        _.includes(assumeuser.groups, 'BS')
      )
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/shippingInstructions' }}
            as={Link}
            eventKey="5"
          >
            <p
              className={
                this.getPathDetails('/shippingInstructions') ||
                this.getPathDetails('/shippingInstructionsDetails')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.shippingInstruction')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], 'SI') ||
      _.includes(jwt(user.idToken)['cognito:groups'], 'BS')
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/shippingInstructions' }}
          as={Link}
          eventKey="5"
        >
          <p
            className={
              this.getPathDetails('/shippingInstructions') ||
              this.getPathDetails('/shippingInstructionDetails')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.shippingInstruction')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderContainerSearch = () => {
    const { assumeuser, user } = this.props;
    let grps = [];
    if (assumeuser !== null) {
      grps = assumeuser.groups;
    } else {
      grps = jwt(user.idToken)['cognito:groups'];
    }
    let shouldNotRernder = true;
    if (
      process.env.REACT_APP_COUNTRY_CODE === 'US' &&
      _.includes(grps, DOCUMENT_TYPE_VGM_DETAILS) &&
      !_.includes(grps, 'BD')
    ) {
      shouldNotRernder = false;
    }

    if (shouldNotRernder) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/search' }}
          as={Link}
          eventKey="4"
        >
          <p
            className={
              this.getPathDetails('/') ||
              this.getPathDetails('/search') ||
              this.getPathDetails('/containerList')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.containerSearch')}
          </p>
        </Nav.Link>
      );
    }
  };

  shouldRenderVGMDetails = () => {
    const { assumeuser, user } = this.props;
    if (assumeuser !== null) {
      if (_.includes(assumeuser.groups, DOCUMENT_TYPE_VGM_DETAILS))
        return (
          <Nav.Link
            className={styles.navLink}
            to={{ pathname: '/vgmDetails' }}
            as={Link}
            eventKey="5"
          >
            <p
              className={
                this.getPathDetails('/vgmDetails')
                  ? styles.titleText_Bold
                  : styles.titleText
              }
            >
              {this.props.t('header.vgmDetails')}
            </p>
          </Nav.Link>
        );
    } else if (
      _.includes(jwt(user.idToken)['cognito:groups'], DOCUMENT_TYPE_VGM_DETAILS)
    ) {
      return (
        <Nav.Link
          className={styles.navLink}
          to={{ pathname: '/vgmDetails' }}
          as={Link}
          eventKey="10"
        >
          <p
            className={
              this.getPathDetails('/vgmDetails')
                ? styles.titleText_Bold
                : styles.titleText
            }
          >
            {this.props.t('header.vgmDetails')}
          </p>
        </Nav.Link>
      );
    }
  };

  render() {
    const { props } = this;
    const { user, assumeuser, location } = this.props;
    const { isShowingModal, txtAssumeUser, isShowingModalUnassume } =
      this.state;
    const { showLoader, showAlertDialog, alertDialogMsg, alertDialogButtons } =
      this.state;
    // return null;
    if (
      location &&
      location.pathname &&
      location.pathname.includes('signintoken')
    )
      return null;
    if (props.user.username !== '')
      return (
        <>
          {showLoader && <ActivityLoader show={showLoader} />}
          {showAlertDialog && (
            <AlertDialog
              show={showAlertDialog}
              msg={alertDialogMsg.split('||')[0]}
              title={alertDialogMsg.split('||')[1]}
              buttons={alertDialogButtons}
              didClose={() =>
                this.setState({ alertDialogMsg: '', showAlertDialog: false })
              }
            />
          )}
          <Navbar
            collapseOnSelect
            expand="lg"
            fixed="top"
            className={[styles.headerBar, 'justify-content-between'].join(' ')}
          >
            <Navbar.Brand>
              <Nav.Link
                style={{ paddingLeft: 0 }}
                to="/"
                href="/"
                as={Link}
                eventKey="1"
              >
                <img
                  className={styles.logo}
                  height="55"
                  width="118"
                  src={require('./../assets/images/logo.png').default}
                  alt="logo"
                />
              </Nav.Link>
            </Navbar.Brand>
            <div>
              {_.includes(jwt(user.idToken)['cognito:groups'], 'CSR') &&
              assumeuser !== null ? (
                <Nav.Link
                  className={styles.navLink}
                  onClick={this.onAssumeChange}
                  eventKey="5"
                >
                  <p className={styles.AssumeTitleText}>
                    &nbsp;Assumed User ID:
                  </p>
                  <span
                    style={{
                      color: '#305F9E',
                      fontSize: '17px',
                      marginLeft: '20px',
                    }}
                  >
                    {assumeuser.username}
                  </span>
                </Nav.Link>
              ) : (
                <p />
              )}
            </div>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              className={'justify-content-end'}
              style={{ backgroundColor: '#fff', margin: '20px 0px' }}
            >
              {this.shouldRenderBookingDashboard()}
              {this.shouldRenderContainerDashboard()}
              {this.shouldRenderVGMDetails()}
              {this.shouldRenderBillingDashboard()}
              {this.shouldRenderBlanketLOI()}
              {this.shouldRenderDetentionCalc()}
              {this.shouldRenderBookingRequest()}
              {this.shouldRenderBookingUpdate()}
              {this.shouldRenderShippingInstruction()}
              {this.shouldRenderRepSalesView()}
              {this.shouldRenderSalesView()}
              {this.shouldRenderContainerSearch()}
              {/* <div style={{ marginLeft: 10 }}> */}
              <div className={styles.collasibleNavDropdownDiv}>
                <NavDropdown
                  alignRight
                  title={
                    // <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <span className={styles.collasibleNavDropdownSpan}>
                      <i
                        style={{
                          color: '#777879',
                          fontSize: 22,
                          marginRight: 5,
                        }}
                        className={['fa fa-user-circle-o']}
                      />
                      {jwt(user.idToken)['given_name'] === undefined
                        ? user.username
                        : jwt(user.idToken)['given_name']}
                      <img
                        src={require('./../assets/images/op1.png').default}
                        style={{ height: 20, width: 20, marginLeft: 5 }}
                        alt="arrow"
                      />
                    </span>
                  }
                  id="header-nav-dropdown"
                  data-testid="header-nav-dropdown"
                  className={styles.collasibleNavDropdown}
                >
                  <NavDropdown.Item
                    eventKey="5"
                    onClick={() => this.changeLanguage('en')}
                    data-testid="menu_lang_english"
                  >
                    English
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="6"
                    onClick={() => this.changeLanguage('zh')}
                    data-testid="menu_lang_mandarin"
                  >
                    Mandarin
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    eventKey="7"
                    onClick={() => this.changeLanguage('vi')}
                    data-testid="menu_lang_vietnamese"
                  >
                    Vietnamese
                  </NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  {this.shouldRenderUserProfile()}
                  {this.shouldRenderInformationCenter()}
                  {_.includes(jwt(user.idToken)['cognito:groups'], 'ADMIN') && (
                    <NavDropdown.Item
                      eventKey="8"
                      onClick={this.didAdminAccountSearch}
                    >
                      Admin - User Search
                    </NavDropdown.Item>
                  )}
                  {_.includes(jwt(user.idToken)['cognito:groups'], 'ADMIN') && (
                    <NavDropdown.Item
                      eventKey="9"
                      onClick={this.didAdminProfileSearch}
                    >
                      Admin - CP Search
                    </NavDropdown.Item>
                  )}
                  {_.includes(jwt(user.idToken)['cognito:groups'], 'ADMIN') && (
                    <NavDropdown.Item
                      eventKey="10"
                      onClick={this.didAdminEtaRuleSearch}
                    >
                      ETA Rule Maintenance
                    </NavDropdown.Item>
                  )}
                  {_.includes(jwt(user.idToken)['cognito:groups'], 'ADMIN') && (
                    <NavDropdown.Item
                      eventKey="10"
                      onClick={this.didAdminEtaRuleExceptionSearch}
                    >
                      ETA Rule Exception Maintenance
                    </NavDropdown.Item>
                  )}
                  {_.includes(jwt(user.idToken)['cognito:groups'], 'CSR') &&
                    (assumeuser ? (
                      <NavDropdown.Item
                        eventKey="8"
                        onClick={this.onAssumeChange}
                      >
                        Release Assumed User
                      </NavDropdown.Item>
                    ) : (
                      <NavDropdown.Item
                        eventKey="8"
                        onClick={() => this.setState({ isShowingModal: true })}
                      >
                        Assume User
                      </NavDropdown.Item>
                    ))}

                  {calcRegion(window.location.href) === 'CN' &&
                    (_.includes(jwt(user.idToken)['cognito:groups'], 'SI') ||
                      _.includes(
                        jwt(user.idToken)['cognito:groups'],
                        'BD'
                      )) && (
                      <NavDropdown.Item eventKey="10" onClick={this.userGuide}>
                        User Guide
                      </NavDropdown.Item>
                    )}
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey="9" onClick={this.didSupportHelp}>
                    Contact us
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="8" onClick={this.changePassword}>
                    Change Password
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item href="#">Logout</NavDropdown.Item> */}
                  <NavDropdown.Item
                    eventKey="8"
                    onClick={() =>
                      this.didLogout(() =>
                        this.props.history.push({
                          pathname: `/signin`,
                          state: { sessionExpired: false },
                        })
                      )
                    }
                  >
                    Logout
                  </NavDropdown.Item>
                  <div className={styles.version}>
                    v{process.env.REACT_APP_VERSION}
                  </div>
                </NavDropdown>
              </div>
            </Navbar.Collapse>

            {isShowingModal && (
              <AssumeUser
                type={props.type}
                txtAssumeUser={txtAssumeUser}
                isShowingModal={isShowingModal}
                handleClose={() => this.setState({ isShowingModal: false })}
                onChange={(e) =>
                  this.setState({ txtAssumeUser: e.target.value })
                }
                handleSave={this.didSubmitAssumeUser}
              />
            )}

            {isShowingModalUnassume && (
              <RemoveAssumeUser
                isShowingModalUnassume={isShowingModalUnassume}
                handleCloseUnassumeNo={() =>
                  this.setState({ isShowingModalUnassume: false })
                }
                handleCloseUnassume={this.handleCloseUnassume}
                assumeUserVal={assumeuser.username}
              />
            )}
          </Navbar>
          {jwt(user.idToken)['cognito:groups'] === undefined && (
            <div className={styles.infobar}>
              <span className={styles.txtInfoNote}>
                Your Matson account is still under review. You will receive an
                email once your account setup is approved.
              </span>
            </div>
          )}
        </>
      );
    else
      return (
        <Navbar
          collapseOnSelect
          expand="lg"
          fixed="top"
          className={[styles.headerBar, 'justify-content-between'].join(' ')}
        >
          <Navbar.Brand>
            <Nav.Link
              style={{ paddingLeft: 0 }}
              to="/"
              href="/"
              as={Link}
              eventKey="1"
            >
              <img
                className={styles.logo}
                height="55"
                width="118"
                src={require('./../assets/images/logo.png').default}
                alt="logo"
              />
            </Nav.Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            className={'justify-content-end'}
            style={{ backgroundColor: '#fff', margin: '20px 0px' }}
          >
            {user.vgm != 'true' && (
              <Nav.Link
                className={styles.navLink}
                to={{ pathname: '/search' }}
                as={Link}
                eventKey="4"
              >
                <p
                  className={
                    this.getPathDetails('/search') ||
                    this.getPathDetails('/containerList') ||
                    this.getPathDetails('/')
                      ? styles.titleText_Bold
                      : styles.titleText
                  }
                >
                  {this.props.t('header.containerSearch')}
                </p>
              </Nav.Link>
            )}
            <Nav.Link
              className={styles.navLink}
              to={{ pathname: '/registration' }}
              as={Link}
              eventKey="5"
            >
              <p
                className={
                  this.getPathDetails('/registration')
                    ? styles.titleText_Bold
                    : styles.titleText
                }
              >
                {this.props.t('header.registration')}
              </p>
            </Nav.Link>
            {(this.props.user.continent_code == 'AS' || process.env.REACT_APP_STAGE !== "production") && <Nav.Link
              className={styles.navLink}
              to={{ pathname: '/detentionChargeCalculator' }}
              as={Link}
              eventKey="5"
            >
              <p
                className={
                  this.getPathDetails('/detentionChargeCalculator')
                    ? styles.titleText_Bold
                    : styles.titleText
                }
              >
                {this.props.t('header.detention')}
              </p>
            </Nav.Link>}
            {this.renderInformationCenterLink()}

            {/* <div style={{ marginLeft: 10 }}> */}
            <div className={styles.collasibleNavDropdownDiv}>
              <NavDropdown
                alignRight
                // title={"Hi " + props.user.username}
                title={
                  // <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <span className={styles.collasibleNavDropdownSpan}>
                    <i
                      style={{ color: '#777879', fontSize: 22, marginRight: 5 }}
                      className={['fa fa-user-circle-o']}
                    />
                    {'Guest'}
                    <img
                      src={require('./../assets/images/op1.png').default}
                      style={{ height: 20, width: 20, marginLeft: 5 }}
                      alt="arrow"
                    />
                  </span>
                }
                id="header-nav-dropdown"
                className={styles.collasibleNavDropdown}
              >
                <NavDropdown.Item
                  eventKey="5"
                  onClick={() => this.changeLanguage('en')}
                >
                  English
                </NavDropdown.Item>
                <NavDropdown.Item
                  eventKey="6"
                  onClick={() => this.changeLanguage('zh')}
                >
                  Mandarin
                </NavDropdown.Item>
                {/* <NavDropdown.Item
                    eventKey="7"
                    onClick={() => this.changeLanguage('vi')}
                    data-testid="menu_lang_vietnamese"
                  >
                    Vietnamese
                  </NavDropdown.Item> */}
                <NavDropdown.Divider />
                {/* <NavDropdown.Item href="#">Logout</NavDropdown.Item> */}
                <NavDropdown.Item eventKey="8" onClick={this.didLogin}>
                  Login
                </NavDropdown.Item>
              </NavDropdown>
            </div>
          </Navbar.Collapse>
        </Navbar>
      );
  }
}

const AssumeUser = ({
  isShowingModal,
  handleClose,
  handleSave,
  onChange,
  txtAssumeUser,
  type,
}) => (
  // <Modal style={{ marginLeft: '35%', marginTop: '5%', marginRight: 'auto', width: '35%' }} show={isShowingModal} onHide={handleClose}>
  <Modal
    className={styles.assumeUserModal}
    show={isShowingModal}
    onHide={handleClose}
  >
    <Modal.Header
      style={{ borderBottom: '#FFFFFF' }}
      closeButton
    ></Modal.Header>
    <Modal.Body
      style={{ marginLeft: '10%', marginRight: '10%', color: '#606162' }}
    >
      Enter Assumed User ID
      <Form className={styles.form}>
        <Form.Row>
          <Form.Control
            type={type || 'text'}
            className={styles.textInput}
            value={txtAssumeUser}
            onChange={onChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSave();
              }
            }}
          />
        </Form.Row>
      </Form>
    </Modal.Body>
    <Modal.Footer
      style={{
        textAlign: 'center',
        borderTop: '#FFFFFF',
        marginLeft: '10%',
        marginRight: '10%',
      }}
    >
      <Button size="lg" block className={styles.button} onClick={handleSave}>
        SUBMIT
      </Button>
    </Modal.Footer>
  </Modal>
);

const RemoveAssumeUser = ({
  handleCloseUnassume,
  handleCloseUnassumeNo,
  isShowingModalUnassume,
  assumeUserVal,
}) => (
  <Modal
    style={{
      marginLeft: '35%',
      marginTop: '5%',
      marginRight: 'auto',
      width: '35%',
    }}
    show={isShowingModalUnassume}
    onHide={handleCloseUnassumeNo}
  >
    <Modal.Header
      style={{ borderBottom: '#FFFFFF' }}
      closeButton
    ></Modal.Header>
    <Modal.Body
      style={{
        marginLeft: '10%',
        marginRight: '10%',
        color: '#606162',
        textAlign: 'center',
        fontSize: 'large',
      }}
    >
      Do you want to release this User?
      <p style={{ color: '#003399', textAlign: 'center', fontSize: 'large' }}>
        {assumeUserVal}
      </p>
    </Modal.Body>
    <Modal.Footer
      style={{
        textAlign: 'center',
        borderTop: '#FFFFFF',
        marginLeft: '10%',
        marginRight: '10%',
      }}
    >
      <Button
        size="lg"
        block
        className={styles.button_no}
        onClick={handleCloseUnassumeNo}
      >
        NO
      </Button>
      <Button
        size="lg"
        block
        className={styles.button_yes}
        onClick={handleCloseUnassume}
        style={{ marginTop: '0px' }}
      >
        YES
      </Button>
    </Modal.Footer>
  </Modal>
);

const ReduxConnectedCompoment = connect(
  (state) => ({ user: state.user, assumeuser: state.assumeuser }),
  {
    setUser,
    resetUser,
    resetDropdowns,
    setAssumeUser,
    resetAssumeUser,
    resetConDashSearchCr,
    resetBookingDashSearch,
    resetVgmLegalInfo,
    setClientInfo,
    resetSelectedFilters,
  }
)(withRouter(Header));

export default withTranslation('common')(ReduxConnectedCompoment);
